import dayjs from 'dayjs';
import type { ITournamentItem } from '../types';

export const getTournamentTimerInfo = (item: ITournamentItem) => {
	const date1 = dayjs(item.dateEnd);
	const all = date1.diff(item.dateStart, 'seconds', true);
	const past = dayjs(new Date()).diff(item.dateStart, 'seconds', true);

	if (past > all) {
		return {
			past: 0,
			all: 0,
		};
	}

	return {
		past,
		all,
	};
};
